export default class NavbarEnum {
    static BODY = 'body'
    static NAVBAR = '[data-element="navbar"]'
    static LOGO = '[data-element="navbar-logo"]'
    static ECOMODE = '[data-id="eco-bar"]'
    static BURGER_MENU_TRIGGER = '[data-trigger="burger-menu-toggle"]'
    static BURGER_MENU_MORE = '[data-trigger="wpiris-menu-more"]'
    static BURGER_MENU_PREV = '[data-trigger="wpiris-menu-prev"]'
    static BURGER_SUBMENU = '[data-trigger="wpiris-menu-submenu"]'
    static SEARCH_TRIGGER = '[data-hc-modal][href="#modal-wp-search"]'
    static SEARCH_MODAL_INPUT = '#modal-wp-search input[name="s"]'
}
